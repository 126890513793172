@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --gradient: #450a0a;
    --background: 0 98.75% 98.32%;
    --foreground: 0 7.5% 0.64%;

    --muted: 0 7.5% 91.6%;
    --muted-foreground: 0 3.75% 41.6%;

    --popover: 0 30% 91.6%;
    --popover-foreground: 0 7.5% 0.8%;

    --card: 0 30% 91.6%;
    --card-foreground: 0 7.5% 0.8%;

    --border: 0 12.5% 89.64%;
    --input: 0 12.5% 89.64%;

    --primary: 0 75% 16%;
    --primary-foreground: 0 1.5% 91.6%;

    --secondary: 0 3.75% 95.8%;
    --secondary-foreground: 0 5.5% 11.6%;

    --accent: 0 3.75% 95.8%;
    --accent-foreground: 0 5.5% 11.6%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 0 75% 16%;

    --radius: 0.5rem;
  }
}

@layer base {
  body {
    background: #fff;
    direction: rtl;
  }
}

details > summary {
  list-style: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #450a0a;
}

@media (prefers-reduced-motion: no-preference) {
  .animate-fedaup {
    @apply animate-fade-up;
  }
}
