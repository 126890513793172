@import "./App.css";

@font-face {
  font-family: "Tajawal";
  src: url("../public/fonts/Tajawal-Bold.ttf") format("truetype");
  /* Add additional src lines for other font variations if available */
  font-weight: normal;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Tajawal;
}
.slider-anmition {
  animation: 20s slide infinite linear;
}
.rever-slider-anmition {
  animation: slide-left 8s infinite linear;
}

:root {
  --white-color: #ffffff;
  --gray-white: #d1d1d1;
}

.no {
  display: none !important;
}
@media (max-width: 700px) {
  .nav {
    position: relative !important;
  }
  .nav,
  .nav ul {
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
  }
  .nav ul {
    margin-top: 10px !important;
    height: 65vh !important;
    width: 95%;
  }
  .nav ul li {
    background-color: #f3f5f6;
    width: 100% !important;
    margin-top: 5px;
    padding: 10px 20px;
    font-size: 32px;
    border-radius: 8px;
  }
  .nav-left-buttons {
    display: block !important;
    width: 90% !important;
  }
  .nav-left-buttons button {
    display: block !important;
    width: 100% !important;
    text-align: start;
    margin-bottom: 10px !important;
    padding: 10px 20px;
    font-size: 32px;
    border-radius: 8px;
  }
  .language-icon {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    text-align: start;
    margin-bottom: 10px !important;
    padding: 10px 20px;
    font-size: 32px;
    border-radius: 8px;
  }
  /* .menu-icon {
    position: absolute !important;
    display: inline !important;
    top: 10px;
    right: 20px;
    cursor: pointer;
  } */
}
/* .menu-icon {
  display: none;
} */
.language-icon {
  display: flex;
  gap: 10px;
}
.nav {
  background-color: white;
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2;
  padding: 0 1rem;
}
.navActive {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 999;
}
.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}
.nav-left-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dropdownlanguage {
  position: absolute;
  top: 4.5rem;
  right: 10px;
  color: black;
  width: 100px;
  text-align: center;
  padding: 15px;
  font-weight: bolder;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dropdownlanguage ul li {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 20px 10px;
  border-radius: 8px;
}
.dropdownlanguage ul li:hover {
  background-color: #333;
  color: white;
  cursor: pointer;
}
.logout {
  position: absolute;
  top: 3.5rem;
  right: 5px;
  color: black;
  width: 200px;
  text-align: center;
  border-radius: 2px;
  font-weight: bolder;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logout ul li {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  border-radius: 4px;
}
.logout ul li:hover {
  background-color: #333;
  color: white;
  cursor: pointer;
}
.logout-ar {
  position: absolute;
  top: 3.5rem;
  left: 5px;
  color: black;
  width: 200px;
  text-align: center;
  border-radius: 2px;
  font-weight: bolder;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logout-ar ul li {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  border-radius: 4px;
}
.logout-ar ul li:hover {
  background-color: #333;
  color: white;
  cursor: pointer;
}
/*  */
.dropdownlanguage-rigth {
  position: absolute;
  top: 4.5rem;
  left: 10px;
  color: black;
  width: 100px;
  text-align: center;
  padding: 15px;
  font-weight: bolder;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.dropdownlanguage-rigth ul li {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 20px 10px;
  border-radius: 8px;
}
.dropdownlanguage-rigth ul li:hover {
  background-color: #333;
  color: white;
  cursor: pointer;
}
/*  */
.logout-rigth {
  position: absolute;
  top: 2.5rem;
  right: 5px;
  color: black;
  width: 200px;
  text-align: center;
  border-radius: 2px;
  font-weight: bolder;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.logout-rigth ul li {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 10px 5px;
  border-radius: 4px;
}
.logout-rigth ul li:hover {
  background-color: #333;
  color: white;
  cursor: pointer;
}
.selected {
  background-color: black;
  color: white;
}

/* .carousel{
  max-width: 700px;
  margin-inline: 2erm;
  border-radius: 10px;
  position: relative;
  margin: 12rem auto;
  display: flex;
  flex-direction: column;
}

.carousel-images img {
  width: 100%;
  aspect-ratio: 16/9;
  transition: opacity 0.3 ease;
}
.carousel-images img.inactive{
  display: none;
}
.carousel-images img.active{
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
opacity: 1;
  }
} */

.carousel_parant {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
}
.carousel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.info-carousel {
  position: absolute;
  width: 32rem;
  height: 10rem;
  background-color: rgb(49, 51, 53, 55%);
  bottom: 1rem;
  color: black;
  right: 5rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: end;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}
.pub-carousel {
  position: absolute;
  width: 25rem;
  height: 10rem;
  background-color: rgb(49, 51, 53, 55%);
  bottom: 1rem;
  color: black;
  right: 2rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: end;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}
.info-carousel-end {
  position: absolute;
  width: 25rem;
  height: 10rem;
  background-color: rgb(49, 51, 53, 55%);
  bottom: 1rem;
  color: black;
  left: 2rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: end;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}
.info-carousel-end-en {
  position: absolute;
  width: 26rem;
  height: 10rem;
  background-color: rgb(49, 51, 53, 55%);
  bottom: 1rem;
  color: black;
  left: 1rem;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: end;
  padding: 20px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}
.title-info-carousel {
  width: 100%;
  text-overflow: ellipsis;
  text-align: justify;
}
.title-pub-carousel {
  width: 100%;
  text-overflow: ellipsis;
  text-align: start;
}
.title-info-carousel-end {
  width: 100%;
  text-overflow: ellipsis;
  text-align: start;
}
.title-info-carousel-end-en {
  width: 100%;
  text-overflow: ellipsis;
  text-align: end;
}
.title-info-carousel h2 {
  font-size: 20px;
  color: var(--white-color);
}
.title-info-carousel p {
  color: var(--gray-white);
}
.title-info-carousel-end h2 {
  font-size: 20px;
  color: var(--white-color);
}
.title-info-carousel-end p {
  color: var(--gray-white);
}
.info-carousel .inside-image {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row-reverse;
}
.inside-image-pub {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row-reverse;
}
.inside-image-en {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row-reverse;
}
.inside-image-en-pub {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  gap: 10px;
  flex-direction: row-reverse;
}
.slide img {
  aspect-ratio: 16/9;
  transition: opacity 0.3 ease;
  object-fit: fill;
}
.slide {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow-left {
  width: 4rem;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.005)
  );
  left: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(100px);
}

.arrow-left:hover {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.01)
  );
}
.arrow-right:hover {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.01)
  );
}
.arrow-right {
  width: 4rem;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.005),
    rgba(0, 0, 0, 0.1)
  );
  right: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(100px);
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: #cca972;
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 6px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
  outline: 1px solid #6c6a6a;
}

.indicator-inactive {
  background-color: #dddddd;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  outline: 1px solid #6c6a6a;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-dots li.slick-active button:before {
  background-color: #cca972;
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 6px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
  outline: 1px solid #6c6a6a;
  color: transparent;
}
.slick-dots li button:before {
  background-color: #dddddd;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  outline: 1px solid #6c6a6a;
  color: transparent;
}
.services:before {
  content: url("./assets/img/Group\ 3610.png");
  position: absolute;
  left: 10px;
  top: 10px;
}
.services-ar:before {
  content: url("./assets/img/Group\ 3610.png");
  position: absolute;
  right: 10px;
  top: 10px;
}
.selected-sidbar {
  background-color: rgba(213, 174, 120, 0.2);
  color: #d5ae78;
}
.publishesTitle {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.publishesHero {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.publishesHero-en {
  text-align: end;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.publishesProject,
.job {
  text-align: end;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.archiveTitle {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.archiveTitleResponsive {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.job {
  text-align: start;
}
.publication {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  width: 100%; /* Ensure the element respects the container's width */
  max-width: 100%; /* Prevent overflow beyond the parent container */
  overflow-wrap: anywhere; /* Allow breaking of long words anywhere */
}
.reportIndex {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  width: 100%; /* Ensure the element respects the container's width */
  max-width: 100%; /* Prevent overflow beyond the parent container */
  overflow-wrap: anywhere; /* Allow breaking of long words anywhere */
}
.publicationEn {
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  width: 100%; /* Ensure the element respects the container's width */
  max-width: 100%; /* Prevent overflow beyond the parent container */
  overflow-wrap: anywhere; /* Allow breaking of long words anywhere */
}
.text-shadow {
  text-shadow: 1px 1px 2px black, 0 0 1em #d5ae78, 0 0 0.2em #cca972;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.p-multiselect-item .p-checkbox {
  display: none;
}
.container-pub {
  width: 100%;
}
@media (min-width: 300px) {
  .container-pub {
    max-width: 400px;
  }
}
@media (min-width: 768px) {
  .container-pub {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container-pub {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container-pub {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container-pub {
    max-width: 1536px;
  }
}
.custom-html-editor ul,
.custom-html-editor ol {
  padding-left: 1.5rem; /* Indent the list */
  list-style-position: outside; /* Make sure markers are outside */
  list-style-type: disc; /* Use bullets for unordered lists */
}

.custom-html-editor ol {
  list-style-type: decimal; /* Use numbers for ordered lists */
}

.custom-html-editor li {
  margin-bottom: 0.5rem; /* Add space between list items */
  padding-right: 0.5rem; /* Adjust space between marker and text */
  text-align: left; /* Ensure the text aligns correctly on the right */
  direction: ltr;
}
.custom-html-editor {
  background-color: #fff; /* Ensure a white background for better readability */
  border-radius: 8px; /* Smooth rounded edges */
}

.custom-html-editor .editor-content {
  padding: 8px; /* Space around the editor content */
}

/* ------------ */
.custom-html-editor-en ul,
.custom-html-editor-en ol {
  padding-right: 1.5rem; /* Indent the list */
  list-style-position: outside; /* Make sure markers are outside */
  list-style-type: disc; /* Use bullets for unordered lists */
}

.custom-html-editor-en ol {
  list-style-type: decimal; /* Use numbers for ordered lists */
}

.custom-html-editor-en li {
  margin-bottom: 0.5rem; /* Add space between list items */
  padding-left: 0.5rem; /* Adjust space between marker and text */
  text-align: right; /* Ensure the text aligns correctly on the right */
  direction: rtl;
}
.custom-html-editor-en {
  background-color: #fff; /* Ensure a white background for better readability */
  border-radius: 8px; /* Smooth rounded edges */
}

.custom-html-editor-en .editor-content-en {
  padding: 8px; /* Space around the editor content */
}

/* ------------- */
.custom-html-content ul,
.custom-html-content ol {
  padding-right: 1.5rem; /* Indent the list */
  list-style-position: outside; /* Make sure markers are outside */
  list-style-type: disc; /* Use bullets for unordered lists */
}

.custom-html-content ol {
  list-style-type: decimal; /* Use numbers for ordered lists */
}

.custom-html-content li {
  margin-bottom: 0.5rem; /* Add space between list items */
  padding-left: 0.5rem; /* Adjust space between marker and text */
  text-align: right; /* Ensure the text aligns correctly on the right */
  direction: rtl;
}
.custom-html-content,
.custom-html-content-view {
  background-color: transparent; /* Ensure a white background for better readability */
  border-radius: 8px; /* Smooth rounded edges */
}

.custom-html-content {
  padding: 8px; /* Space around the editor content */
}
/* ------------- */
.custom-html-content-en ul,
.custom-html-content-en ol {
  padding-left: 1.5rem; /* Indent the list */
  list-style-position: outside; /* Make sure markers are outside */
  list-style-type: disc; /* Use bullets for unordered lists */
}

.custom-html-content-en ol {
  list-style-type: decimal; /* Use numbers for ordered lists */
}

.custom-html-content-en li {
  margin-bottom: 0.5rem; /* Add space between list items */
  padding-right: 0.5rem; /* Adjust space between marker and text */
  text-align: left; /* Ensure the text aligns correctly on the right */
  direction: ltr;
}
.custom-html-content-en {
  background-color: transparent; /* Ensure a white background for better readability */
  border-radius: 8px; /* Smooth rounded edges */
}

.custom-html-content {
  padding: 8px; /* Space around the editor content */
}

@media (prefers-reduced-motion: no-preference) {
  .fedain {
    scale: 0.8;
    opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view(200px);
    animation-range: entry 0% cover 30%;
  }
  @keyframes fade-in {
    to {
      scale: 1;
      opacity: 1;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .fedaup {
    animation: fade-up 3s linear forwards;
    animation-timeline: view();
    animation-range: entry 0% cover 20%;
  }
  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
